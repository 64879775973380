import type { Clerk } from '@clerk/types';

/**
 * Opens the Clerk modal for sign in or sign up.
 * If the application is running as a browser extension, it will redirect to the appropriate page.
 * Otherwise, it will open the sign in or sign up modal using the Clerk SDK.
 *
 * @param redirectUrl - The URL to redirect to after successful sign in or sign up.
 * @param type - The type of modal to open. Can be 'SIGN_IN' or 'SIGN_UP'.
 */
export async function openClerkModal({
	clerk: cl,
	redirectUrl,
	type
}: {
	clerk: Clerk | null;
	redirectUrl: string;
	type: 'SIGN_IN' | 'SIGN_UP';
}) {
	if (import.meta.env.VITE_IS_EXTENSION === 'true') {
		window.location.href = `${import.meta.env.VITE_WEBAPP_URL}${type === 'SIGN_IN' ? '/signin' : '/signup'}`;
		return;
	}

	if (!cl) {
		throw new Error('Clerk is not initialized');
	}

	switch (type) {
		case 'SIGN_UP':
			cl?.openSignUp({
				redirectUrl
			});
			break;
		case 'SIGN_IN':
			cl?.openSignIn({
				redirectUrl
			});
			break;
	}
}
