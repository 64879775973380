<!-- 
	@component
	
	AVATAR
	
	@prop path - The path to the image.
	@prop alt - The alt text of the image.
	@prop title - The title of the image.
	@prop height - The height of the image.
	@prop width - The width of the image.
	@prop rounded - The border radius of the image.
	@prop background - The background color of the image.
	@prop border - The border of the image.
	@prop classes - The classes to apply to the image.

 -->
<script lang="ts">
	import type { CssClasses } from '@skeletonlabs/skeleton';

	import Image from './Image.svelte';

	export let path: null | string;
	export let alt: string = '';
	export let title: string | undefined = undefined;
	export let height: CssClasses = 'h-6 tablet:h-8';
	export let width: CssClasses = 'w-6 tablet:w-8';
	export let rounded: CssClasses = 'rounded-full';
	export let background: CssClasses = 'bg-white';
	export let border: CssClasses = '';
	export let classes: CssClasses = '';
</script>

<Image
	{alt}
	classes="{height} {width} shrink-0 {background} {rounded} {border} brightness-90 duration-300 group-hover:brightness-100 {classes}"
	extensionImgOverrides={{
		imgClasses: `w-full h-full object-cover ${rounded}`,
		wrapperClasses: `overflow-hidden !shrink-0 ${background} ${border} ${height} ${width} ${rounded} ${classes}`
	}}
	mode="contain"
	placeholder="maincolor"
	ratio="1:1"
	src="/avatars/{path ?? 'default.jpeg'}"
	{title}
/>
